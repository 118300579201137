/* global $ */
import { Controller } from '@hotwired/stimulus';
import "jquery-ui/ui/widgets/datepicker";

export default class extends Controller {
  static targets = ['input']

  connect () {
    $(this.inputTarget).datepicker({
      showAnim:'slideDown',
      minDate: 0
    });
  }
}
