import { Controller } from '@hotwired/stimulus';

// Stimulus port of jQuery UI Stars v3.0.1
//
// This is intended to be connected to a select element whose options are the
// ratings to choose between.
//
// Dispatches a 'stars.value-changed' event on the controller element when the
// rating is changed.
export default class extends Controller {
  static values = {
    selected: Number,
  }

  connect() {
    this.options = {
      // Default options below:
      starClass: 'ui-stars-star',
      starOnClass: 'ui-stars-star-on',
      starHoverClass: 'ui-stars-star-hover',
    }

    var starId = 0;
    let options = this.options
    options.val2id = [];
    options.id2val = [];
    options.id2title = [];
    options.name = this.element.name;

    this.selectedValue = this.element.value
    const starOptions = this.element.querySelectorAll('option');
    /*
     * Map all starOption inputs to an array of star DOM elements
     */
    let stars = []

    starOptions.forEach(function (element, i) {
      var el = {
        value:      element.value,
        title:      element.text || element.value,
        isDefault:  element.defaultSelected || element.defaultChecked
      };

      if (i == 0) {
        // Ignore the no-rating option
        return
      }

      options.val2id[el.value] = starId;
      options.id2val[starId] = el.value;
      options.id2title[starId] = el.title;

      if(el.isDefault) {
        options.checked = starId;
        options.value = options.defaultValue = el.value;
        options.title = el.title;
      }

      const link = document.createElement('a')
      link.textContent = el.value

      const star = document.createElement('div')
      star.classList.add(options.starClass)
      star.append(link)

      starId++;
      stars.push(star)
    });

    /*
     * Append Stars interface
     */
    stars.forEach(star => {
      var starElement = this.element.insertAdjacentElement('beforebegin', star);

      /*
      * Attach stars event handlers
      */
      starElement.addEventListener('click', function (e) {
        var i = stars.indexOf(e.target.parentElement);

        this.options.checked = i;
        this.options.value = this.options.id2val[i];
        this.options.title = this.options.id2title[i];

        this.selectedValue = this.options.value

        this.fillTo(i, false);
      }.bind(this))

      starElement.addEventListener('mouseover', function (e) {
        var i = this.starElements.indexOf(e.target.parentElement);
        this.fillTo(i, true);
      }.bind(this))

      starElement.addEventListener('mouseout', function (e) {
        this.fillTo(this.options.checked, false);
      }.bind(this))
    })

    this.starElements = stars


    /*
     * Initial selection
     */
    if(this.options.checked === undefined) {
      this.options.checked = -1;
      this.options.value = this.options.defaultValue
      this.options.title = '';
    }

    this.makeOriginalInputInert();
    this.createInputElement()

    /*
     * Finally, set up the Stars
     */
    this.select(this.selectedValue);
  }

  // The only FORM element, that has been linked to the stars control. The value
  // field is updated on each Star click event
  createInputElement() {
    this.inputElement = document.createElement('input')
    this.inputElement.setAttribute('type', 'hidden')
    this.inputElement.setAttribute('name', this.options.name)
    this.inputElement.setAttribute('value', this.options.value)
    this.element.insertAdjacentElement('afterend', this.inputElement);
  }

  disconnect() {
    this.inputElement.remove();
  }

  /*
  * Star selection helpers
  */
  fillTo(index, hover) {
    if (index != -1) {
      var addClass = hover ? this.options.starHoverClass : this.options.starOnClass;
      var remClass = hover ? this.options.starOnClass : this.options.starHoverClass;

      this.starElements.forEach((star, i) => {
        if (i <= index) {
          star.classList.remove(remClass)
          star.classList.add(addClass)
        } else if (i > index) {
          star.classList.remove(this.options.starHoverClass)
          star.classList.remove(this.options.starOnClass);
        }
      })
    }
    else this.fillNone();
  };

  fillNone() {
    this.starElements.forEach(star => {
      star.classList.remove(this.options.starOnClass)
      star.classList.remove(this.options.starHoverClass);
    })
  };

  makeOriginalInputInert() {
    this.element.setAttribute("disabled", "disabled")
    this.element.setAttribute("type", "hidden")
    this.element.style = "display: none;"
  }

  select(val) {
    var e = this.selectedStar()
    this.options.forceSelect = true;
    this.fillTo(this.selectedIndex());
    this.options.forceSelect = false;
  }

  selectedValueChanged() {
    if (this.inputElement) {
      this.inputElement.setAttribute('value', this.selectedValue)
    }

    const event = new CustomEvent(
      "stars.value-changed",
      {
        bubbles: true,
        detail: {
          value: this.selectedValue,
        }
      },
    );
    this.element.dispatchEvent(event);
  }

  selectedIndex() {
    return this.selectedValue - 1
  }

  selectedStar() {
    return this.starElements[this.selectedIndex()]
  }
}
